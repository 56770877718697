import React, { useState } from 'react';
import './style.css';
import {
    Container, Row, Col,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import Fade from 'react-reveal/Fade';
import { isMobile } from 'react-device-detect';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const menu1 = [
    {
        src: require('../../assets/img/menus/new-menu-1/0001.png').default,
    },
    {
        src: require('../../assets/img/menus/new-menu-1/0002.png').default,
    },
    {
        src: require('../../assets/img/menus/new-menu-1/0003.png').default,
    },
    {
        src: require('../../assets/img/menus/new-menu-1/0004.png').default,
    },
    {
        src: require('../../assets/img/menus/new-menu-1/0005.png').default,
    },
    {
        src: require('../../assets/img/menus/new-menu-1/0006.png').default,
    },
    {
        src: require('../../assets/img/menus/new-menu-1/0007.png').default,
    },
];

const menu2 = [
    {
        src: require('../../assets/img/menus/new-menu-2/0001.png').default,
    },
    {
        src: require('../../assets/img/menus/new-menu-2/0002.png').default,
    },
    {
        src: require('../../assets/img/menus/new-menu-2/0003.png').default,
    },
    {
        src: require('../../assets/img/menus/new-menu-2/0004.png').default,
    },
    {
        src: require('../../assets/img/menus/new-menu-2/0005.png').default,
    },
    {
        src: require('../../assets/img/menus/new-menu-2/0006.png').default,
    },
    {
        src: require('../../assets/img/menus/new-menu-2/0007.png').default,
    },
];

const Menu = (props) => {
    let animating;

    const [activeIndex, setActiveIndex] = useState(0);
    const [activeIndex2, setActiveIndex2] = useState(0);

    const onExiting = () => { animating = true }
    const onExited = () => { animating = false }

    const slides = menu1.map((item, k) => {
        return (
            <CarouselItem
                onExiting={onExiting}
                onExited={onExited}
                key={k}>
                <img src={item.src} alt={k} style={{ width: '100%' }} />
            </CarouselItem>
        );
    });

    const slide2 = menu2.map((item, k) => {
        return (
            <CarouselItem
                onExiting={onExiting}
                onExited={onExited}
                key={k}>
                <img src={item.src} alt={k} style={{ width: '100%' }} />
            </CarouselItem>
        );
    });

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === menu1.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? menu1.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const next2 = () => {
        if (animating) return;
        const nextIndex = activeIndex2 === menu2.length - 1 ? 0 : activeIndex2 + 1;
        setActiveIndex2(nextIndex);
    }

    const previous2 = () => {
        if (animating) return;
        const nextIndex = activeIndex2 === 0 ? menu2.length - 1 : activeIndex2 - 1;
        setActiveIndex2(nextIndex);
    }

    return _webView()

    function _mobileView() {
        // return (
        //     <div style={{marginTop: 50, marginBottom: 50}}>
        //         <Fade>
        //             <center style={{marginTop: 0, marginBottom: 10}}>
        //                 <h1 className="menu-title-mobile">MENU</h1>
        //                 <p style={{fontSize: 10}}>(Pinch to zoom the menu)</p>
        //             </center>
        //             <TransformWrapper>
        //                 <TransformComponent>
        //                     <img 
        //                         src={require('../../assets/img/menu.jpg').default}
        //                         className="image"
        //                         />
        //                 </TransformComponent>
        //             </TransformWrapper>
        //         </Fade>
        //     </div>
        // )
    }

    function _webView() {
        return (
            <div style={{ paddingLeft: 50, paddingRight: 50 }}>
                <Row>
                    <Col md="6" xs="12">
                        <Fade left>
                            <div style={{ marginBottom: 20 }}>
                                <center>
                                    <span style={{ color: '#8E7F60', fontSize: 40, letterSpacing: 2 }}>Menu (EN)</span><br /><br />
                                </center>
                                <Carousel
                                    activeIndex={activeIndex}
                                    next={next}
                                    previous={previous}
                                    interval={false}
                                >
                                    {/* <CarouselIndicators items={menu1} activeIndex={activeIndex} onClickHandler={this.goToIndex} /> */}
                                    {slides}
                                    <CarouselControl style={{ backgroundColor: '#385D7E' }} direction="prev" directionText="Previous" onClickHandler={previous} />
                                    <CarouselControl style={{ backgroundColor: '#385D7E' }} direction="next" directionText="Next" onClickHandler={next} />
                                </Carousel>
                            </div>
                        </Fade>
                    </Col>
                    <Col md="6" xs="12">
                        <Fade right>
                            <div>
                                <center>
                                    <span style={{ color: '#8E7F60', fontSize: 40, letterSpacing: 2 }}>Menu (AR)</span><br /><br />
                                </center>
                                <Carousel
                                    activeIndex={activeIndex2}
                                    next={next2}
                                    previous={previous2}
                                    interval={false}
                                >
                                    {/* <CarouselIndicators items={menu1} activeIndex={activeIndex} onClickHandler={this.goToIndex} /> */}
                                    {slide2}
                                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous2} />
                                    <CarouselControl direction="next" directionText="Next" onClickHandler={next2} />
                                </Carousel>
                            </div>
                        </Fade>
                    </Col>
                </Row>
                {/* <Container>
                    <Fade>
                        <img 
                            src={require('../../assets/img/menu.jpg').default}
                            className="image"
                            />
                    </Fade>
                </Container> */}
            </div>
        )
    }
}

export default Menu;