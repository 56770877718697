import React from 'react';
import { Fade } from 'react-reveal';
import { Row, Col } from 'reactstrap';
import './style.css';
import { isMobile } from 'react-device-detect';

const Images = (props) => {
    return(
        <div>
            <div className='imgContainer'>
                <img src={require('../../assets/img/links/bg.jpg').default} style={{width: '100%', height: 650, objectFit: 'cover'}}/>
                <div className='centered'>
                    <Fade top>
                        <div>
                            <strong>PASSION FOR FOOD</strong> <br />
                            <strong>SOCIAL INTERACTION</strong> <br />
                            <strong>GRACIOUS HOSPITALITY</strong>
                        </div>
                    </Fade>
                </div>
            </div>
            <div style={{marginBottom: 100}}/>
            {isMobile ? _mobileView() : _webView()}
        </div>
    )

    function _mobileView() {
        return (
            <Row>
                <Col xs="12">
                    <Fade left>
                        <img src={require('../../assets/img/links/b1.jpeg').default} style={{width: '100%', height: 300, objectFit: 'cover'}}/>
                    </Fade>
                </Col>
                <Col xs="12">
                    <Fade left>
                        <img src={require('../../assets/img/links/2.jpeg').default} style={{width: '100%', height: 300, objectFit: 'cover'}}/>
                    </Fade>
                </Col>
                <Col xs="12">
                    <Fade bottom>
                        <img src={require('../../assets/img/links/3.jpeg').default} style={{width: '100%', height: 300, objectFit: 'cover'}}/>
                    </Fade>
                </Col>
                <Col xs="12">
                    <Fade right>
                        <img src={require('../../assets/img/links/4.jpg').default} style={{width: '100%', height: 300, objectFit: 'cover'}}/>
                    </Fade>
                </Col>
                <Col xs="12">
                    <Fade right>
                        <img src={require('../../assets/img/links/5.jpg').default} style={{width: '100%', height: 300, objectFit: 'cover'}}/>
                    </Fade>
                </Col>
            </Row>
        )
    }

    function _webView() {
        return (
            <div className='gridContainer'>
                <div className='gridInnerLeft'>
                    <Fade left>
                        <img src={require('../../assets/img/links/b1.jpeg').default} style={{width: '100%', height: 300, objectFit: 'cover', marginBottom: 30}}/>
                    </Fade>
                    <Fade left>
                        <img src={require('../../assets/img/links/2.jpeg').default} style={{width: '100%', height: 300, objectFit: 'cover'}}/>
                    </Fade>
                </div>
                <div className='gridInnerMiddle'>
                    <Fade bottom>
                        <img src={require('../../assets/img/links/3.jpeg').default} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                    </Fade>
                </div>
                <div className='gridInnerRight'>
                    <Fade right>
                        <img src={require('../../assets/img/links/4.jpg').default} style={{width: '100%', height: 300, objectFit: 'cover', marginBottom: 30}}/>
                    </Fade>
                    <Fade right>
                        <img src={require('../../assets/img/links/5.jpg').default} style={{width: '100%', height: 300, objectFit: 'cover'}}/>
                    </Fade>
                </div>
            </div>
        )
    }
}

export default Images;