import React, { useEffect, useState } from 'react';
import './style.css';
import Header from '../../components/header';
import Fade from 'react-reveal/Fade';
import video from '../../assets/img/Video/video1.mp4'
import { Button, Fade as Faded, Row, Col } from 'reactstrap';
import { Link } from 'react-scroll';
import ReactPlayer from 'react-player'

const Home = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <div>
            <div className="header-visible">
                <Header />
            </div>
            <div className='topContainer'>
                <div className="player-wrapper">
                    {isLoaded === false && isLoading === false &&
                        <Faded exit={isLoading}>
                            <div className="img-wrapper">
                                <div className='bg-image-banner' />
                                {/* <img 
                                    src={require('../../assets/img/links/banner-min.jpeg').default} 
                                    style={{
                                        height: '100vh',
                                        width: '100%',
                                        objectFit: 'cover'
                                    }}
                                    /> */}
                            </div>
                        </Faded>
                    }

                    <Faded in={isLoaded}>
                        <ReactPlayer
                            className="react-player"
                            url={video}
                            playing
                            loop
                            muted
                            style={{
                                zIndex: -1,
                                margin: 0,
                                padding: 0
                            }}
                            width='100%'
                            height='100%'
                            objectFit="cover"
                            onReady={() => {
                                setTimeout(() => {
                                    setIsLoaded(true);
                                    setIsLoading(true);
                                }, 5000);
                            }}
                        />
                    </Faded>
                </div>
                {/* <div className='backDrops'></div> */}
                <div className='topCentered'>
                    <Fade bottom>
                        <Row>
                            <Col>
                                <Link smooth={true} duration={250} to="menuElement">
                                    <Button outline size='lg' className='btnTop'>MENU</Button>
                                </Link>
                            </Col>
                            <Col>
                                <Button outline size='lg' className='btnTop' href="https://www.sevenrooms.com/reservations/marinasocial?venues=marinasocial,lamer" target="_blank">RESERVATION</Button>
                            </Col>
                        </Row>
                    </Fade>
                </div>
            </div>
            {/* <img src={require('../../assets/img/links/banner.jpg').default} style={{width: '100%', height: 800, objectFit: 'cover'}}/> */}
        </div>
    )
}

export default Home;