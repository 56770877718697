import React from 'react';
import './style.css';
import { Row, Col } from 'reactstrap'
import { Fade } from 'react-reveal';

const About = (props) => {
    return(
        <div>
            <Row>
                <Col md="6">
                    <Fade big>
                        <img src={require('../../assets/img/links/about.jpeg').default} style={{width: '100%', height: 750, objectFit: 'cover', marginBottom: 20}}/>
                    </Fade>
                </Col>
                <Col md="6" style={{fontFamily: 'MyriadPro-Light'}}>
                    <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <Fade right>
                            <p style={{letterSpacing: 15, fontSize: 65, color: '#8E7F60'}}>ABOUT US</p>
                            <p style={{color: '#fff', fontSize: 23, letterSpacing: 2}}>
                                Located at the newly opened Jeddah Yacht Club on the Red Sea, Marina Social in one of the first Mediterranean fine dining restaurants in Jeddah.
                            </p><br/>
                            <p style={{color: '#fff', fontSize: 23, letterSpacing: 2}}>
                                Marina Social is a unique restaurant featuring passion for food, social interaction, excellence and gracious hospitality. Guests can experience a world of flavors on menu, passions and culinary creativity with a selection of artfully crafted mocktails.
                            </p><br/>
                            <p style={{color: '#fff', fontSize: 23, letterSpacing: 2}}>
                                The restaurant delivers an informal and social setting, where the surroundings are as vibrant as the food. Marina Social is classy and comfort within a relaxed, social atmosphere against the backdrop of the stunning panoramic views of the Marina overlooking luxury yachts and range of fashion stores and restaurants.
                            </p>
                        </Fade>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default About;