import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { blackColor, whiteColor } from '../common/brand';
import Fade from 'react-reveal/Fade';
import { isMobile } from 'react-device-detect';

function Footer(props) {
  return (
    <div style={{backgroundColor: whiteColor, padding: 30}}>
      <Row>
        <Col md="4" xs="12"></Col>
        <Col md="4" xs="12">
            <br/><br/>
            <center>
              <a href="javascript:void" onClick={() => _socialMedia('ig')} style={{cursor: 'pointer'}}>
                <Fade left>
                  <img src={require('../assets/img/icons/ig.png').default} width="35"/>
                </Fade>
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="javascript:void" onClick={() => _socialMedia('twitter')} style={{cursor: 'pointer'}}>
                <Fade right>
                  <img src={require('../assets/img/icons/twitter.png').default} width="35"/>
                </Fade>
              </a>
              {/* <AiOutlineTwitter color="#EAE1D6" size={40} onClick={() => _socialMedia('twitter')} style={{cursor: 'pointer'}}/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <AiOutlineInstagram color="#EAE1D6" size={40} onClick={() => _socialMedia('ig')} style={{cursor: 'pointer'}}/> */}
            </center>
            <br/><br/>
        </Col>
        <Col md="4" xs="12"></Col>
      </Row>
      <br/><br/>
      <span style={{color: blackColor, fontSize: 12, fontFamily: 'Nexa-Light'}}>
        <Fade>
          Copyright © 2021 Maraya Social - All Rights Reserved.
        </Fade>
      </span>
      {isMobile ? <><br/><br/><br/></> : null}
      {/* <div className="floatingBtn">
        <Button 
          size="lg"
          block={isMobile ? true : false}
          className="btn-reserve" 
          onClick={() => _reserveNow()}
          >
          <span>RESERVE NOW</span>
        </Button>
      </div> */}
    </div>
  );

  function _reserveNow() {
    window.open('https://www.sevenrooms.com/reservations/marayasocial', '_blank');
  }

  function _socialMedia(social) {
    if(social === 'ig') {
      window.open('https://www.instagram.com/marayasocial/?hl=en', '_blank');
    }else {
      window.open('https://twitter.com/marayasocial', '_blank');
    }
  }
}

export default Footer;
