import React from 'react';
import './style.css';
import Footer from '../components/footer';
import { Element } from 'react-scroll';
import { isMobile } from 'react-device-detect';

import Home from './home/index';
import About from './about/index';
import Menu from './menu/index';
import Contact from './contact/index';
import Location from './location/index';
import Images from './images';
import Gallery from './gallery';

const SinglePage = (props) => {
    return(
        <div>
            <Element id="homeElement">
                <Home />
            </Element>
            <div style={{marginBottom: 100}}/>
            <Element id="aboutElement">
                <About />
            </Element>
            <div style={{marginBottom: 100}}/>
            <Element id="imagesElement">
                <Images />
            </Element>
            <div style={{marginBottom: 100}}/>
            <Element id="menuElement">
                <Menu />
            </Element>
            <div style={{marginBottom: 100}}/>
            <Element id="contactElement">
                <Contact/>
            </Element>
            {/* {isMobile ? null : 
                <img src={require('../assets/img/outline.png').default} className="outline-bg"/>
            } */}
            {/* <div className={isMobile ? "" : "bg-outline"}> */}
            {/* <div className="content-position">
                <Element id="menuElement">
                    <Menu/>
                </Element>
                <Element id="contactElement">
                    <Contact/>
                </Element>
            </div> */}
            {/* <Element id="locationElement">
                <Location />
            </Element>
            <Footer /> */}
        </div>
    );

    function _action(page) {

    }
}

export default SinglePage;