import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import SinglePage from './views/single_page';
// import Home from './views/home';
// import HomeDetails from './views/home_details';
// import Page2 from './views/page2';
// import Page3 from './views/page3';
// import Page4 from './views/page4';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={SinglePage} />
        {/* <Route path='/details/:id' component={HomeDetails} />
        <Route path='/about' component={Page2} />
        <Route path='/services' component={Page3} />
        <Route path='/contactus' component={Page4} /> */}

        <Redirect from="*" to="/"/>
      </Switch>
    </Router>
  );
}

export default App;
