import React, { useState, useEffect } from 'react';
import './style.css';

import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem
} from 'reactstrap';

import { FiMenu } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
import Sidebar from "react-sidebar";
import { primaryColor } from '../common/brand';
import { Link } from 'react-scroll';

function Header(props) {

  const [sideBarOpen, setSideBarOpen] = useState(false);

  function onSetSidebarOpen(action) {
    console.log('hey')
    setSideBarOpen(action);
  }

  return (
    <div>
      <Sidebar
        sidebar={
          <div style={styles.sideBarContainer}>
            <a href="javascript:void" onClick={() => onSetSidebarOpen(false)} style={{textDecoration: 'none'}}>
              <AiOutlineClose color={primaryColor} size="25"/>
            </a>
            <br/><br/><br/>
            <Link smooth={true} duration={250} to="homeElement" style={{cursor: 'pointer'}}>
              <p style={styles.menuTxt}>HOME</p>
            </Link>
            <Link smooth={true} duration={250} to="aboutElement" style={{cursor: 'pointer'}}>
              <p style={styles.menuTxt}>ABOUT</p>
            </Link>
            <Link smooth={true} duration={250} to="imagesElement" style={{cursor: 'pointer'}}>
              <p style={styles.menuTxt}>GALLERY</p>
            </Link>
            <Link smooth={true} duration={250} to="menuElement" style={{cursor: 'pointer'}}>
              <p style={styles.menuTxt}>MENU</p>
            </Link>
            <Link smooth={true} duration={250} to="locationElement" style={{cursor: 'pointer'}}>
              <p style={styles.menuTxt}>RESERVATIONS</p>
            </Link>
            <Link smooth={true} duration={250} to="contactElement" style={{cursor: 'pointer'}}>
              <p style={styles.menuTxt}>CONTACT US</p>
            </Link>
          </div>
        }
        open={sideBarOpen}
        onSetOpen={onSetSidebarOpen}
        styles={{ sidebar: { background: "#18211F", position: "fixed", zIndex: 1000 } }}
        //pullRight
        >
        <Navbar expand="md">
          <Nav style={{marginTop: 40}}>
            <NavItem onClick={() => onSetSidebarOpen(true)} style={{cursor: 'pointer', marginTop: -25}}>
              <FiMenu color={primaryColor} size="30"/>
            </NavItem>
          </Nav>
          {/* <NavbarBrand>
            <img 
              src={require('../assets/img/logo-maraya.png').default}
              className="logo"
              onClick={() => window.location.href = '/'}
              />
          </NavbarBrand>
           */}
        </Navbar>
      </Sidebar>
      {/* {props.children} */}
    </div>
  );
}

const styles = {
  sideBarContainer: {
    width: '300px',
    textAlign: 'left',
    padding: 40,
    zIndex: 1000
  },
  menuTxt: {
    fontSize: 18,
    color: '#EDE4D9',
    letterSpacing: 2
  }
}

export default Header;
