import React from 'react';
import './style.css';
import { Row, Col } from 'reactstrap';
import { Fade } from 'react-reveal';

const Contact = (props) => {

    return (
        <div>
            <Row style={{ marginBottom: 100 }}>
                <Col md="6">
                    <Fade left>
                        <center>
                            <span style={{ color: '#8E7F60', fontSize: 40, letterSpacing: 2 }}>CONTACT US</span><br />
                            <a href="mailto:dine@marinasocial.com" style={{ color: '#fff', fontSize: 25, letterSpacing: 2 }}>dine@marinasocial.com</a><br />
                            <span style={{ color: '#fff', fontSize: 25, letterSpacing: 2 }}>+966 53 677 7901</span><br /><br />
                            <div style={{ width: 200, height: 2, backgroundColor: '#8E7F60', marginBottom: 20 }} />
                        </center>
                    </Fade>
                </Col>
                <Col md="6">
                    <Fade right>
                        <center>
                            <span style={{ color: '#8E7F60', fontSize: 40, letterSpacing: 2 }}>OPERATING HOURS</span><br />
                            <span style={{ color: '#fff', fontSize: 25, letterSpacing: 2 }}>Daily from</span><br />
                            <span style={{ color: '#fff', fontSize: 25, letterSpacing: 2 }}>5pm to 1am</span><br /><br />
                            <div style={{ width: 200, height: 2, backgroundColor: '#8E7F60', marginBottom: 20 }} />
                        </center>
                    </Fade>
                </Col>
            </Row>
            <center>
                <div style={{ marginBottom: 100 }}>
                    <Fade bootom>
                        <center>
                            <span style={{ color: '#8E7F60', fontSize: 40, letterSpacing: 2 }}>LOCATION</span><br />
                            <span style={{ color: '#fff', fontSize: 25, letterSpacing: 2 }}>Jeddah Yacht Club, Saudi Arabia</span><br /><br />
                            <div style={{ width: 200, height: 2, backgroundColor: '#8E7F60', marginBottom: 20 }} />
                            <br />
                            <div style={{ padding: 10 }}>
                                <a href='https://www.google.com/maps/place/Marina+Social+By+Jason+Atherton/@21.6508604,39.0998613,17z/data=!3m1!4b1!4m6!3m5!1s0x15c3d9445cce8893:0x1ecda00cb918e3e9!8m2!3d21.6508604!4d39.0998613!16s%2Fg%2F11t6y5w7v5?entry=ttu' target='_blank'>
                                    <img src={require('../../assets/img/map.png').default} style={{ width: '100%', height: 350, objectFit: 'contain' }} />
                                </a>
                            </div>
                        </center>
                    </Fade>
                </div>
            </center>
            {/* <Row style={{marginBottom: 100}}>
                <Col md="6">
                    <Fade bottom>
                        <center>
                            <span style={{color: '#8E7F60', fontSize: 40, letterSpacing: 2}}>OPERATING HOURS</span><br/>
                            <span style={{color: '#fff', fontSize: 25, letterSpacing: 2}}>Sunday to Saturday from 6pm to 1am</span><br/><br/>
                            <div style={{width: 200, height: 2, backgroundColor: '#8E7F60', marginBottom: 20}}/>
                        </center>
                    </Fade>
                </Col>
                <Col md="6">
                    <Fade bottom>
                        <center>
                            <span style={{color: '#8E7F60', fontSize: 40, letterSpacing: 2}}>ENTERTAINMENT</span><br/>
                            <span style={{color: '#fff', fontSize: 25, letterSpacing: 2}}>Wednesday to Sunday from 8pm to 1am</span><br/><br/>
                            <div style={{width: 200, height: 2, backgroundColor: '#8E7F60', marginBottom: 20}}/>
                        </center>
                    </Fade>
                </Col>
            </Row> */}
            <Fade big>
                <center>
                    <img src={require('../../assets/img/links/footer.png').default} style={{ width: 300, height: 200, objectFit: 'contain' }} />
                </center>
            </Fade>
        </div>
    )
}

export default Contact;